import { Controller } from "@hotwired/stimulus";
import { MapboxGeocoder } from "@mapbox/search-js-web";

// Connects to data-controller="address-geocoding"
export default class extends Controller {
  static targets = ["latitude", "longitude", "address"];

  connect() {
    this.element.setAttribute(
      "data-action",
      "turbo:morph@window->address-geocoding#reconnect"
    );

    const geocoderElement = new MapboxGeocoder();

    geocoderElement.accessToken = window.Alorempla.mapbox.apiKey;
    geocoderElement.placeholder = this.addressTarget.value;

    // set the options property
    geocoderElement.options = {
      language: window.Alorempla.locale,
      country: "FR",
    };

    geocoderElement.addEventListener("retrieve", (e) => {
      const feature = e.detail;
      this.addressTarget.value = feature.properties.full_address;
      this.latitudeTarget.value = feature.properties.coordinates.latitude;
      this.longitudeTarget.value = feature.properties.coordinates.longitude;
    });

    this.element.appendChild(geocoderElement);
  }

  reconnect() {
    this.disconnect();
    this.connect();
  }
}
