import { Controller } from "@hotwired/stimulus";
import { autofill, config } from "@mapbox/search-js-web";

// Connects to data-controller="address-autocomplete"
export default class extends Controller {
  static targets = ["search"];

  connect() {
    this.element.setAttribute(
      "data-action",
      "turbo:morph@window->address-autocomplete#reconnect"
    );

    config.accessToken = window.Alorempla.mapbox.apiKey;

    autofill({
      options: {
        country: "fr",
        locale: window.Alorempla.locale,
      },
    });

    // Remove address-search from search input name
    const name = this.searchTarget.name.replace(" address-search", "");
    this.searchTarget.name = name;
  }

  reconnect() {
    this.disconnect();
    this.connect();
  }
}
