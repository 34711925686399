import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import I18n from "../../i18n.js";

// Connects to data-controller="forms--replacement"
export default class extends Controller {
  static targets = ["startDate", "endDate", "dateRange"];
  static values = { startDate: String, endDate: String };

  connect() {
    if (this.hasDateRangeTarget) {
      this.initializeFlatpickr();
    }
  }

  initializeFlatpickr() {
    this.flatpickr = flatpickr(this.dateRangeTarget, {
      mode: "range",
      minDate: "today",
      time_24hr: true,
      locale: this.getLocale(),
      defaultDate: this.defaultDateRange(),
      onChange: this.setDateRange.bind(this),
    });
  }

  setDateRange(selectedDates) {
    if (selectedDates.length === 2) {
      this.startDateTarget.value = this.formatDate(
        this.adjustForTimezone(selectedDates[0])
      );
      this.endDateTarget.value = this.formatDate(
        this.adjustForTimezone(selectedDates[1])
      );
    } else {
      this.startDateTarget.value = "";
      this.endDateTarget.value = "";
    }
  }

  defaultDateRange() {
    if (this.hasStartDateValue && this.hasEndDateValue) {
      return [this.startDateValue, this.endDateValue];
    }

    return [];
  }

  adjustForTimezone(date) {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate;
  }

  formatDate(date) {
    return date.toISOString().split("T")[0];
  }

  getLocale() {
    switch (window.Alorempla.locale) {
      case "fr":
        return French;
      default:
        return "en";
    }
  }

  disconnect() {
    if (this.flatpickr) {
      this.flatpickr.destroy();
    }
  }
}
