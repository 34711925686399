import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

// Connects to data-controller="select-multiple"
export default class extends Controller {
  connect() {
    this.element.setAttribute(
      "data-action",
      "turbo:morph@window->select-multiple#reconnect"
    );

    this.select = new TomSelect(this.element, {
      plugins: ["remove_button"],
    });
  }

  disconnect() {
    this.select.destroy();
  }

  reconnect() {
    this.disconnect();
    this.connect();
  }
}
