import { Controller } from "@hotwired/stimulus";
import mapboxgl from "mapbox-gl";

// Connects to data-controller="map"
export default class extends Controller {
  static values = {
    latitude: Number,
    longitude: Number,
  };

  initialize() {
    mapboxgl.accessToken = window.Alorempla.mapbox.apiKey;

    this.element.parentElement.style.height = "calc(100% - 4rem)";
    this.element.style.height = "100%";
  }

  connect() {
    this.map = new mapboxgl.Map({
      container: this.element.id,
      interactive: true,
      attributionControl: false,
      locale: window.Alorempla.locale,
      zoom: 12,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [this.longitudeValue, this.latitudeValue],
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.marker = new mapboxgl.Marker()
      .setLngLat([this.longitudeValue, this.latitudeValue])
      .addTo(this.map);
  }
}
