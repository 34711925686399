import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="layout"
export default class extends Controller {
  static targets = ["mobileMenu", "mobileMenuOpenIcon", "mobileMenuCloseIcon"]

  toggleMobileMenu() {
    this.mobileMenuOpenIconTarget.classList.toggle("hidden")
    this.mobileMenuCloseIconTarget.classList.toggle("hidden")
    this.mobileMenuTarget.classList.toggle("hidden")
  }
}
