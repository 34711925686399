import { Controller } from "@hotwired/stimulus";
import GLightbox from "glightbox";

// Connects to data-controller="gallery"
export default class extends Controller {
  static targets = ["item"];

  connect() {
    this.lightbox = GLightbox({
      elements: this.itemTargets,
      loop: true,
    });
  }
}
