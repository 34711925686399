import { Controller } from "@hotwired/stimulus";
import { Calendar } from "@fullcalendar/core";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import I18n from "../i18n.js";
import frLocale from "@fullcalendar/core/locales/fr";
import Swal from "sweetalert2";

// Connects to data-controller="schedule-editor"
export default class extends Controller {
  static values = {
    scheduleId: Number,
    scheduleSlotsUrl: String,
    scheduleSlotTypes: Array,
  };

  connect() {
    this.setupCalendar();
  }

  setupCalendar() {
    this.calendar = new Calendar(this.element, {
      schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
      plugins: [interactionPlugin, resourceTimeGridPlugin],
      initialView: "resourceTimeGridDay",
      locale: frLocale,
      timeZone: window.Alorempla.timeZone,
      headerToolbar: {
        left: "",
        center: "",
        right: "",
      },
      allDaySlot: false,
      editable: true,
      eventResourceEditable: true,
      selectable: true,
      events: this.scheduleSlotsUrlValue,
      select: (info) => {
        this.createScheduleSlot(info);
      },
      eventResize: (info) => {
        this.updateScheduleSlot("resized", info);
      },
      eventDrop: (info) => {
        this.updateScheduleSlot("dropped", info);
      },
      eventClick: (info) => {
        info.jsEvent.preventDefault();

        const event = info.event;
        const currentSlotType = event.extendedProps.slot_type;
        const slotTypesOptions = event.extendedProps.available_slot_types
          .map(
            (type) =>
              `<option value="${type}" ${
                type === currentSlotType ? "selected" : ""
              }>${I18n.t(
                "activerecord.attributes.schedule_slot.slot_types." + type
              )}</option>`
          )
          .join("");

        Swal.fire({
          title: I18n.t("installed.schedules.show.delete_or_edit_slot"),
          html: `
            <div>
              <label for="schedule-slot-name">
                ${I18n.t("installed.schedules.show.slot_title")}
              </label>
              <br />
              <input id="schedule-slot-name" class="swal2-input" value="${
                event.title
              }">
            </div>
            <br />
            <div>
              <label for="schedule-slot-slot-type">
                ${I18n.t("installed.schedules.show.slot_type")}
              </label>
              <br />
              <select id="schedule-slot-slot-type" class="swal2-input">
                ${slotTypesOptions}
              </select>
            </div>
          `,
          showCancelButton: true,
          confirmButtonText: I18n.t("installed.schedules.show.save"),
          cancelButtonText: I18n.t("installed.schedules.show.delete"),
          preConfirm: () => {
            const name = Swal.getPopup().querySelector(
              "#schedule-slot-name"
            ).value;

            const selectedSlotType = Swal.getPopup().querySelector(
              "#schedule-slot-slot-type"
            ).value;

            // if (!name) {
            //   Swal.showValidationMessage(
            //     I18n.t("installed.schedules.show.slot_title_cannot_be_empty")
            //   );
            //   return false;
            // }

            return { name, selectedSlotType };
          },
        }).then((result) => {
          if (result.isConfirmed) {
            fetch(info.event.url, {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector(
                  'meta[name="csrf-token"]'
                ).content,
              },
              body: JSON.stringify({
                schedule_slot: {
                  name: result.value.name,
                  slot_type: result.value.selectedSlotType,
                },
              }),
            })
              .then((response) => response.json())
              .then(() => {
                this.calendar.refetchEvents();
              })
              .catch((error) => {
                console.error("Error: ", error);
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            fetch(info.event.url, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector(
                  'meta[name="csrf-token"]'
                ).content,
              },
            })
              .then((response) => response.json())
              .then(() => {
                // info.event.remove();
                this.calendar.refetchEvents();
              })
              .catch((error) => {
                console.error("Error: ", error);
              });
          }
        });
      },
      resources: [
        { id: 1, title: I18n.t("date.abbr_day_names")[1] },
        { id: 2, title: I18n.t("date.abbr_day_names")[2] },
        { id: 3, title: I18n.t("date.abbr_day_names")[3] },
        { id: 4, title: I18n.t("date.abbr_day_names")[4] },
        { id: 5, title: I18n.t("date.abbr_day_names")[5] },
        { id: 6, title: I18n.t("date.abbr_day_names")[6] },
        { id: 7, title: I18n.t("date.abbr_day_names")[0] },
      ],
    });

    this.calendar.render();
  }

  createScheduleSlot(info) {
    const slotTypesOptions = this.scheduleSlotTypesValue
      .map(
        (type) =>
          `<option value="${type}">${I18n.t(
            "activerecord.attributes.schedule_slot.slot_types." + type
          )}</option>`
      )
      .join("");

    Swal.fire({
      title: I18n.t("installed.schedules.show.create_slot"),
      html: `
            <div>
              <label for="schedule-slot-name">
                ${I18n.t("installed.schedules.show.slot_title")}
              </label>
              <br />
              <input id="schedule-slot-name" class="swal2-input">
            </div>
            <br />
            <div>
              <label for="schedule-slot-slot-type">
                ${I18n.t("installed.schedules.show.slot_type")}
              </label>
              <br />
              <select id="schedule-slot-slot-type" class="swal2-input">
                ${slotTypesOptions}
              </select>
            </div>
          `,
      confirmButtonText: I18n.t("installed.schedules.show.save"),
      preConfirm: () => {
        const name = Swal.getPopup().querySelector("#schedule-slot-name").value;

        const selectedSlotType = Swal.getPopup().querySelector(
          "#schedule-slot-slot-type"
        ).value;

        // if (!name) {
        //   Swal.showValidationMessage(
        //     I18n.t("installed.schedules.show.slot_title_cannot_be_empty")
        //   );
        //   return false;
        // }

        return { name, selectedSlotType };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(this.scheduleSlotsUrlValue, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
              .content,
          },
          body: JSON.stringify({
            schedule_slot: {
              name: result.value.name,
              slot_type: result.value.selectedSlotType,
              day: info.resource.id,
              starts_at: info.startStr,
              ends_at: info.endStr,
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            // this.calendar.addEvent(data);
            this.calendar.refetchEvents();
          })
          .catch((error) => {
            console.error("Error: ", error);
          });
      }
    });
  }

  updateScheduleSlot(eventType, info) {
    const data = {
      starts_at: info.event.startStr,
      ends_at: info.event.endStr,
    };

    if (info.newResource !== null && info.newResource !== undefined) {
      data.day = info.newResource.id;
    }

    fetch(info.event.url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        schedule_slot: data,
      }),
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error: ", error);
      });
  }
}
