import { Controller } from "@hotwired/stimulus";
import { Calendar } from "@fullcalendar/core";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import I18n from "../i18n.js";
import frLocale from "@fullcalendar/core/locales/fr";

// Connects to data-controller="schedule"
export default class extends Controller {
  static values = {
    scheduleSlotsUrl: String,
  };

  connect() {
    this.setupCalendar();
  }

  setupCalendar() {
    this.calendar = new Calendar(this.element, {
      schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
      plugins: [interactionPlugin, resourceTimeGridPlugin],
      initialView: "resourceTimeGridDay",
      locale: frLocale,
      timeZone: window.Alorempla.timeZone,
      headerToolbar: {
        left: "",
        center: "",
        right: "",
      },
      allDaySlot: false,
      editable: false,
      eventResourceEditable: false,
      selectable: false,
      events: this.scheduleSlotsUrlValue,
      resources: [
        { id: 1, title: I18n.t("date.abbr_day_names")[1] },
        { id: 2, title: I18n.t("date.abbr_day_names")[2] },
        { id: 3, title: I18n.t("date.abbr_day_names")[3] },
        { id: 4, title: I18n.t("date.abbr_day_names")[4] },
        { id: 5, title: I18n.t("date.abbr_day_names")[5] },
        { id: 6, title: I18n.t("date.abbr_day_names")[6] },
        { id: 7, title: I18n.t("date.abbr_day_names")[0] },
      ],
      slotLabelDidMount: (info) => {
        info.el.innerHTML = info.el.innerHTML.replace(" h", "h");
      },
    });

    this.calendar.render();
  }
}
