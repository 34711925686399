import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="conversation"
export default class extends Controller {
  static targets = ["message"];
  static values = { url: String };

  connect() {
    this.scrollDown();
  }

  messageTargetConnected(target) {
    if (target.dataset.read === "false") {
      target.scrollIntoView({ behavior: "smooth", block: "center" });
      target.dataset.read = "true";

      this.markConversationAsRead();
    }
  }

  markConversationAsRead() {
    fetch(this.markAsReadUrl(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.csrfToken(),
      },
    });
  }

  markAsReadUrl() {
    return `${this.urlValue}/mark_as_read`;
  }

  scrollDown() {
    document.querySelector("#new-message").scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  csrfToken() {
    const token = document.querySelector('meta[name="csrf-token"]');
    return token ? token.content : null;
  }
}
